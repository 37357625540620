<template>
  <el-card style="overflow: auto;height: 99%">
    <div>
        <div class="popularize_header">平台返利(成功推广给用户注册后融资成功，您可获得融资利息收益的8%现金返利)</div>
        <div style="font-size: 1.5rem;margin: 1.5rem;color:#666">可提现金额(满100起提)</div>
        <div style="display: flex;align-items: center;margin: 1.5rem">
          <span style="font-size: 3rem;margin-right: 2rem">¥{{UserInfo.balance}}</span>
          <el-button class="main_btn1 withdraw_deposit" @click="WithdrawalApplyVisible=true">我要提现</el-button>
          <el-button class="main_btn1 withdraw_deposit" style="margin-left: 1.5rem" @click="GetHistory">提现记录</el-button>
        </div>
    </div>
    <div style="margin-top: 3.5rem">
      <div class="popularize_header">推广用户</div>
      <div style="margin-top: 1.5rem;margin-bottom: 1rem">
        <el-button class="main_btn4 popularize_btn" @click="IntroduceVisible=true">提交介绍资料</el-button>
        <el-button class="main_btn4 popularize_btn" style="margin-left: 1.5rem" @click="CopyLink">复制专属推广链接</el-button>
      </div>
      <el-table
          :header-cell-style="{'text-align':'center','font-size':'1.125rem'}"
          :cell-style="{'text-align':'center','font-size':'1.125rem'}"
          :data="IntroduceList"
      >
        <el-table-column label="姓名">
          <template slot-scope="scope">
            {{scope.row.name}}
          </template>
        </el-table-column>
        <el-table-column label="手机号">
          <template slot-scope="scope">
            {{scope.row.mobile}}
          </template>
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span :style="{color:['#FFBA56','#0063BF','#BF0000'][scope.row.makafin_status-1]}">{{['沟通中','已成交','未成交'][scope.row.makafin_status-1]}}</span>
          </template>
        </el-table-column>
        <el-table-column label="提交时间">
          <template slot-scope="scope">
            {{scope.row.create_at}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="margin-top: 3.5rem">
      <div class="popularize_header" style="margin-bottom: 1rem">返利详情</div>
      <el-table
          :header-cell-style="{'text-align':'center','font-size':'1.125rem'}"
          :cell-style="{'text-align':'center','font-size':'1.125rem'}"
          :data="RebateData"
      >
        <el-table-column label="姓名">
          <template slot-scope="scope">
            {{scope.row.name}}
          </template>
        </el-table-column>
        <el-table-column label="手机号">
          <template slot-scope="scope">
            {{scope.row.mobile}}
          </template>
        </el-table-column>
        <el-table-column label="获得返利">
          <template slot-scope="scope">
            ¥{{scope.row.rebate_money || '0'}}
          </template>
        </el-table-column>
        <el-table-column label="成交时间">
          <template slot-scope="scope">
              {{scope.row.verify_time ||  "-"}}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
        :visible.sync="IntroduceVisible"
        width="26.5rem"
        :close-on-click-modal="false"
        class="details-dialog-css border_radius"
        @close="close('IntroduceForm')"
    >
      <template slot="title">
        <div style="text-align: center;font-size: 1.75rem">提交介绍资料</div>
      </template>
      <div>
        <el-form ref="IntroduceForm" label-position="top" label-width="80px" :rules="IntroduceRules" :model="IntroduceForm" >
          <el-form-item prop="name">
            <template slot="label">
              <span style="font-size: 1.125rem">姓名</span>
            </template>
            <el-input v-model="IntroduceForm.name"></el-input>
          </el-form-item>
          <el-form-item  prop="mobile">
            <template slot="label">
              <span style="font-size: 1.125rem">手机号</span>
            </template>
            <el-input v-model="IntroduceForm.mobile"></el-input>
          </el-form-item>
          <el-form-item  prop="desc" >
            <template slot="label">
              <span style="font-size: 1.125rem">描述</span>
            </template>
            <el-input v-model="IntroduceForm.desc" type="textarea" :rows="4" resize="none"></el-input>
          </el-form-item>
        </el-form>
        <el-button class="main_btn4 popularize_btn" style="width: 100%" @click="SubmitIntroduce">提交</el-button>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="WithdrawalHisVisible"
        width="80%"
        :close-on-click-modal="false"
        class="details-dialog-css border_radius"
    >
      <template slot="title">
        <div style="text-align: center;font-size: 1.75rem;margin-bottom: 1.125rem">提现记录</div>
      </template>
      <div>
        <el-table
            border
            :height="450"
            :header-cell-style="{'text-align':'center','background-color':'#f5f7fa','font-size':'1.125rem'}"
            :cell-style="{'text-align':'center','font-size':'1.125rem'}"
            :data="WithdrawalHisData"
        >
          <el-table-column label="提现金额">
            <template slot-scope="scope">
                {{scope.row.money}}
            </template>
          </el-table-column>
          <el-table-column label="姓名">
            <template slot-scope="scope">
                {{scope.row.name}}
            </template>
          </el-table-column>
          <el-table-column label="银行卡号">
            <template slot-scope="scope">
                {{scope.row.bank_number}}
            </template>
          </el-table-column>
          <el-table-column label="开户行">
            <template slot-scope="scope">
                {{scope.row.bank_name}}
            </template>
          </el-table-column>
          <el-table-column label="申请时间">
            <template slot-scope="scope">
                {{scope.row.create_at}}
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <div style="display: flex;justify-content: center;align-items: center">
                <span :style="{color:['#333','#0063BF','#BF0000'][scope.row.status-1]}">{{['审核中','提现成功','提现失败'][scope.row.status-1]}}</span>
                <el-tooltip  placement="top" v-if="scope.row.status===3">
                  <template slot="content">
                    <div style="width: 100px">{{scope.row.refuse_desc}}</div>
                  </template>
                  <img style="width: 1.5rem;height: 1.5rem;cursor: pointer" :src="require('@/assets/icon_question_error.png')" alt="">
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="返利时间">
            <template slot-scope="scope">
                {{scope.row.rebate_time || '-'}}
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: center;width: 100%;margin: 2.375rem 0">
            <el-button class="cancel_btn"  @click="WithdrawalHisVisible=false">关闭</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
        :visible.sync="WithdrawalApplyVisible"
        width="43.375rem"
        :close-on-click-modal="false"
        class="details-dialog-css border_radius"
        @close="close('WithdrawalForm')"
    >
      <template slot="title">
          <div style="text-align: center;font-size: 1.75rem;margin-bottom: 3rem">提现申请</div>
      </template>
      <el-form  ref="WithdrawalForm" label-position="right" label-width="9.375rem"  :rules="WithdrawalRules" :model="WithdrawalForm" >
        <el-form-item prop="money">
          <template slot="label">
            <span style="font-size: 1.125rem">提现金额</span>
          </template>
          <el-input-number class="step_btn" v-model="WithdrawalForm.money" @change="MoneyChange" :min="100" :max="+UserInfo.balance" :step="100" step-strictly></el-input-number>
          <p style="font-size: 12px;color: #999">满100起提,需按100的倍数提现</p>
        </el-form-item>
        <el-form-item  prop="name">
          <template slot="label">
            <span style="font-size: 1.125rem">姓名</span>
          </template>
          <el-input v-model="WithdrawalForm.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item  prop="bank_number">
          <template slot="label">
            <span style="font-size: 1.125rem">银行卡号</span>
          </template>
          <el-input v-model="WithdrawalForm.bank_number" placeholder="请输入银行卡号"></el-input>
        </el-form-item>
        <el-form-item prop="bank_name">
          <template slot="label">
            <span style="font-size: 1.125rem">
              开户行<br/>(具体到支行)
            </span>
          </template>
            <el-input style="margin-top:1rem" v-model="WithdrawalForm.bank_name" placeholder="请输入开户行"></el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center;margin-top: 2rem">
        <el-button class="main_btn4 popularize_btn" style="width: 8.25rem;font-size: 1.125rem" @click="WithdrawalApply">确认</el-button>
        <el-button class="cancel_btn" @click="WithdrawalApplyVisible=false">取消</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
import {ENV, INTERFACE_URL} from '../../../../config/index'
export default {
  name: "PromotionRebate",
  data(){
    var  validateTrim = (rule, value, callback) => {
      if(!value.trim()){
        callback(new Error('请输入您的姓名'));
      }
      callback()
    }
    var  validateBankTrim = (rule, value, callback) => {
      if(!value.trim()){
        callback(new Error('请输入开户行'));
      }
      callback()
    }

    var  validatemobile = (rule, value, callback) => {
      if(!(/^1[3456789]\d{9}$/.test(value))){
        callback(new Error('请输入正确的手机号码格式'));
      }
      callback()
    }
    var  validateBankID = (rule, value, callback) => {
      if(!(/^([1-9]{1})(\d{15}|\d{18})$/.test(value))){
        callback(new Error('请输入正确的银行卡号'));
      }
      callback()
    }
    return {
      //用户信息
      UserInfo:{},
      //提交介绍人信息
      IntroduceVisible:false,
      //提现记录
      WithdrawalHisVisible:false,
      WithdrawalHisData:[],
      //提现申请
      WithdrawalApplyVisible:false,
      //提现表单
      WithdrawalForm:{
        money:100,//金额
        name:'',//姓名
        bank_number:'',//银行卡号
        bank_name:'',//银行名称
      },
      //推荐人信息
      IntroduceForm:{
        name:'',
        mobile:'',
        desc:''
      },
      IntroduceList:[],
      //返利列表
      RebateData:[],
      //校验规则
      IntroduceRules:{
        name:[
          { required: true, message: '请输入您的姓名', trigger: 'blur'},
          {validator:validateTrim,trigger: 'blur'}
        ],
        mobile:[
          { required: true, message: '请输入手机号', trigger: 'blur'},
          {validator:validatemobile,trigger: 'blur'}
        ],
      },
      //提现校验规则
      WithdrawalRules:{
        name:[
          { required: true, message: '请输入您的姓名', trigger: 'blur'},
            {validator:validateTrim,trigger: 'blur'},
        ],
        bank_name:[
          { required: true, message: '请输入开户行', trigger: 'blur'},
            {validator:validateBankTrim,trigger: 'blur'}
        ],
        bank_number:[
          { required: true, message: '请输入银行卡号', trigger: 'blur'},
          {validator:validateBankID,trigger: 'blur'}
        ],
      }
    }
  },
  mounted() {
    this.GetUserInfo()
    this.GetWithdraw()
    this.GetRebate()
  },
  methods:{
    MoneyChange(){
      if(!this.WithdrawalForm.money){
        this.WithdrawalForm.money=100
      }
      if(this.WithdrawalForm.money>this.UserInfo.balance){
        this.WithdrawalForm.money=this.UserInfo.balance
      }
    },
    //复制专属链接
    CopyLink(){
      let url=''
      if(ENV==='publish'){
        url='https:'+INTERFACE_URL+'client/#/home/index?pid='+this.UserInfo.id+'&makafin_source=2'
      }else{
        url=INTERFACE_URL+'client/#/home/index?pid='+this.UserInfo.id+'&makafin_source=2'
      }
      this.$utils.copyString(url,this.$message)
    },
    //获取用户信息
    GetUserInfo(){
      this.$api.post('v1/api/makafin/base_account/get',{},res=>{
        this.UserInfo=res
      })
    },
    //获取推广用户
      GetWithdraw(){
        this.$api.post('v1/api/makafin/base_account/level',{},res=>{
          this.IntroduceList=res.list
        })

      },
    //获取提现记录
    GetHistory(){
      this.$api.post('v1/api/makafin/withdrawal/tabulation',{},res=>{
        this.WithdrawalHisData=res.list
        this.WithdrawalHisVisible=true
      })
    },
    //获取返利记录
    GetRebate(){
      this.$api.post('v1/api/makafin/loan_order/rebate',{},res=>{
        this.RebateData=res.list
      })
    },
    //提交介绍人
    SubmitIntroduce(){
      this.$refs['IntroduceForm'].validate((valid) => {
        if(valid){
            this.$api.post('v1/api/makafin/base_account/recommend',this.IntroduceForm,res=>{
              this.$message({
                message:'提交成功',
                type:'success',
                customClass: 'messageIndex'
              })
                this.IntroduceVisible=false
                this.GetWithdraw()
            })
        }
      })
    },
    //提现申请
    WithdrawalApply(){
      this.$refs['WithdrawalForm'].validate((valid) => {
        if (valid) {
          this.$api.post('v1/api/makafin/withdrawal/add', this.WithdrawalForm, res => {
            this.$message({
              message: '申请成功',
              type:'success',
              customClass: 'messageIndex'
            })
            this.WithdrawalApplyVisible = false
            this.GetUserInfo()
          })
        }
      })
    },
    //关闭模态框
    close(obj){
      this.$refs[obj].resetFields();
    }
  }
}
</script>
<style>
.cancel_btn.el-button{
  width: 8.25rem;
  font-size: 1.125rem;
  border: 1px solid #FFBA56!important;
}
.cancel_btn.el-button:hover , .cancel_btn.el-button:focus{
  color:#FFBA56;
  background: #FFF;
}
.cancel_btn.el-button:active{
  color:#FFBA56;
}
.step_btn{
  border:1px solid #FFBA56;
  border-radius:4px 4px;
}
.step_btn .el-input-number__decrease.is-disabled,.step_btn .el-input-number__increase.is-disabled{
  background: #EFEFEF;
  border: 0;
  color:#999;
}
.step_btn .el-input-number__decrease.is-disabled:hover,.step_btn .el-input-number__increase.is-disabled:hover{
  background: #EFEFEF;
  color:#999;
}
.step_btn .el-input-number__decrease,.step_btn .el-input-number__increase{
  border-right:1px solid #FFBA56;
  background: #FFBA56;
  color: #161616;
}

</style>
<style scoped>
.border_radius{
  border-radius: 10px;
}
.popularize_btn{
  background: #FFBA56;
  color:#000;
  font-size: 1.125rem;
}
.popularize_header{
  font-size: 1.5rem;
  padding-left: 1rem;
  border-left: 4px solid #FFBA56;
}
.withdraw_deposit{
  width: 10.5rem;
  background: #FF914D;
  color:#fff;
  font-size: 1.125rem;
  font-weight: bold;
}
</style>